<template>
    <v-container fluid>
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field
                        placeholder="Procurar"
                        dense
                        solo
                        background-color="#FFFFFF"
                        hide-details
                        v-model="search"
                    ></v-text-field>                    
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn block color="amber darken-4" dark @click="dialogCreate()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5 full-height">
            <v-data-table
                :headers="header"
                :items="sensibility"
                dense
                locale="pt-BR"
                :search="search"
                :items-per-page="10"
            >
                <template v-slot:item.idData="{ item }">
                    {{ getDataName(item.idData) }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                <TheToolTip label="Editar">
                    <v-btn icon small tile>
                    <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                    </v-btn>
                </TheToolTip>
                <TheToolTip label="Remover">
                    <v-btn icon small tile>
                    <v-icon @click="remove(item.id)">mdi-minus</v-icon>
                    </v-btn>
                </TheToolTip>
                </template>
            </v-data-table>
        </v-card>

        <TheDialog v-model="dialog" :title="title" :icon="pageIcon">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col>
                            <b>Dados<sup>*</sup></b>
                            <v-select
                                placeholder="Selecione"
                                v-model="object.id_data"
                                :items="data"
                                item-text="name"
                                item-value="id"
                                class="my-2"
                                dense
                                hide-details
                                outlined
                                color="primary"
                                :rules="[requiredRule]"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col md="6" sm="12" cols="12">
                        <b>Tipo</b>
                        <v-select
                            :items="types"
                            class="mt-2"
                            placeholder="Tipo"
                            v-model="object.type"
                            dense
                            outlined
                            color="primary"
                            :rules="[requiredRule]"
                        ></v-select>
                        </v-col>
                        <v-col md="6" sm="12" cols="12">
                        <b>Sensibilidade</b>
                        <v-select
                            :items="options"
                            class="mt-2"
                            placeholder="Sensibilidade"
                            v-model="object.sensibility"
                            dense
                            hide-details
                            outlined
                            color="primary"
                            :rules="[requiredRule]"
                        ></v-select>
                        </v-col>
                    </v-row>                    
                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"                 
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>
    </v-container>
</template>

<script>
    
    import TheTitle from "@/components/TheTitle"
    import TheDialog from "@/components/TheDialog"
    import TheToolTip from "@/components/TheToolTip"
    import axios from "axios"
    import { baseApiUrl, showError } from "@/global"

    export default{
        name: "SensibilidadeDados",

        components: {
            TheTitle,
            TheDialog,
            TheToolTip,
        },

        data: () => ({
            baseRoute: "datasensitivity",
            title: "Sensibilidade de Dados",
            pageIcon: "mdi-database-eye",

            data: {},
            object: {},
            sensibility: [],

            dialog: false,
            valid: false,
            search: null,
            error: false,
            edit: false,

            header: [
                { text: "Dados", value: "idData" },
                { text: "Tipo", value: "type" },
                { text: "Sensibilidade", value: "sensibility" },
                { text: "Ações", value: "action", width: "10%" },
            ],

            options: [
                { text: "Comum", value: "Comum" },
                { text: "Sensível", value: "Sensível" },
                { text: "Criança e Adolescente", value: "Criança e Adolescente" },
            ],
            types: [
                { text: "Pessoal", value: "Pessoal" },
                { text: "Anonimizado", value: "Anonimizado" },
            ],


        }),

        computed: {

            getDataName() {
            return function(idData) {
                const data = this.data.find((el) => el.id === idData);
                if (data) {
                return data.name;
                } else {
                return "";
                }
            };
            },
        },

        methods: {

            loadData() {
                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}`
                axios
                    .get(url)
                    .then((res) => {
                    this.$store.dispatch("setLoadingInfo", false)
                    this.sensibility = res.data
                    })
                    .catch(showError)
            },                       

            loadDados() {
                this.$store.dispatch("setLoadingInfo", true);
                const url = `${baseApiUrl}/data`;
                return axios.get(url)
                    .then((res) => {
                        this.$store.dispatch("setLoadingInfo", false);
                        this.data = res.data;
                    })                    
                    .catch(showError);                
            },

            dialogCreate() {
                this.object = {}
                this.edit = false
                this.dialog = true
            },

            dialogUpdate(item) {
                this.object = item;
                this.object.id_data = item.idData
                this.edit = true
                this.dialog = true
            }, 

            resolveForm(){
                this.$refs.form1.validate()
                if (!this.valid){
                    this.$store.dispatch("setErrorInfo", {
                        open: true,
                        text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                        button: "Ok!",
                    })
                    return
                }
                if(this.edit){
                    this.update()
                } else {
                    this.save()
                }
            },
            
            save(){
                this.error = false
                const url = `${baseApiUrl}/${this.baseRoute}`
                const objects = this.object                
                axios
                    .post(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                    this.dialog = false
                    })                   
            },

            update() {
                this.error = false
                const id = this.object.id
                const url = `${baseApiUrl}/${this.baseRoute}/${id}`
                const objects = this.object
                delete objects.name
                delete objects.idData
                delete objects.id

                delete objects.createdAt
                delete objects.updatedAt
                delete objects.deletedAt
                axios
                    .put(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                    this.dialog = false
                    })
            },
                
            remove(id) {
                if (confirm("Remove?")) {
                    const url = `${baseApiUrl}/${this.baseRoute}/${id}`
                    axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
                }
            },
            
        },

        async mounted() {
            await this.loadDados()
            this.loadData()
        },
    }

</script>

<style>
.full-height {
  height: 80vh;
}
</style>